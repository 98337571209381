import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { LegalInfo, Provider, ProviderItem, ProviderItemActionEnum, WebcoffeeLinks } from '@webcoffee/interfaces';
import { EMPTY, Observable, catchError, mergeMap, switchMap, tap } from 'rxjs';
import { ToasterService } from '../../shared/utils/services/toastr.service';

export interface ProviderState {
    providers: Provider[];
    legalInfos: LegalInfo[];
    selectedProvider: Provider | null;
    providerFormGroup: FormGroup | null;
    query: { search?: string };
    syncing: Set<string>;
    showProviderProducts: boolean;
    providerProducts: null | any;
    providerProductsFilters: {
        search: string;
        page: number;
        [ProviderItemActionEnum.DEACTIVATE]: boolean | null;
        [ProviderItemActionEnum.DELETE]: boolean | null;
        [ProviderItemActionEnum.FORCE_UPDATE]: boolean | null;
        [ProviderItemActionEnum.SYNC]: boolean | null;
        [ProviderItemActionEnum.VERIFIED]: boolean | null;
        new: boolean | null;
    };
    syncProviderItems: boolean;
}

@Injectable()
export class ProviderStore extends ComponentStore<ProviderState> {
    constructor(
        private http: HttpClient,
        private toastr: ToasterService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        super({
            providers: [],
            legalInfos: [],
            selectedProvider: null,
            providerFormGroup: null,
            query: {},
            syncing: new Set(),
            showProviderProducts: false,
            providerProducts: null,
            providerProductsFilters: {
                search: '',
                page: 0,
                [ProviderItemActionEnum.DEACTIVATE]: null,
                [ProviderItemActionEnum.DELETE]: null,
                [ProviderItemActionEnum.FORCE_UPDATE]: null,
                [ProviderItemActionEnum.SYNC]: null,
                [ProviderItemActionEnum.VERIFIED]: null,
                new: null,
            },
            syncProviderItems: false,
        });
    }

    readonly getProviders = this.select((state) => {
        const search = state.query?.search;

        if (search && search.trim() !== '') {
            return state.providers.filter((p) => p.name.toLowerCase().search(search.toLowerCase()) !== -1);
        }

        return state.providers;
    });
    readonly getQuery = this.select((state) => state.query);
    readonly getLegalInfos = this.select((state) => state.legalInfos);
    readonly getSelectedProvider = this.select((state) => state.selectedProvider);
    readonly getProviderFormGroup = this.select((state) => state.providerFormGroup);
    readonly getSyncing = this.select((state) => state.syncing);
    readonly getShowProviderProducts = this.select((state) => state.showProviderProducts);
    readonly getProviderProductsFilters = this.select((state) => state.providerProductsFilters);
    readonly getProviderProducts = this.select((state) => state.providerProducts);
    readonly getSyncProviderItems = this.select((state) => state.syncProviderItems);
    readonly getFilteredProviderProducts = this.select(this.getProviderProductsFilters, this.getProviderProducts, (filters, products) => {
        let filteredProducts = null;
        if (products) {
            if (filters.search && filters.search.trim() !== '') {
                const lowerSearch = filters.search.toLowerCase();

                filteredProducts = products.filter(
                    (p) =>
                        (p.sku && p.sku.toLowerCase().indexOf(lowerSearch) !== -1) ||
                        (p.name && p.name.toLowerCase().indexOf(lowerSearch) !== -1) ||
                        (p.feed &&
                            Object.values(p.feed).some((f) =>
                                Object.values(f)
                                    .map((v) => `${v}`.toLowerCase())
                                    .some((s) => s.indexOf(lowerSearch) !== -1),
                            )),
                );
            }

            if (filters[ProviderItemActionEnum.DEACTIVATE] !== null) {
                if (filteredProducts) {
                    filteredProducts = filteredProducts.filter((p) => p.deactivate === filters[ProviderItemActionEnum.DEACTIVATE]);
                } else {
                    filteredProducts = products.filter((p) => p.deactivate === filters[ProviderItemActionEnum.DEACTIVATE]);
                }
            }
            if (filters[ProviderItemActionEnum.DELETE] !== null) {
                if (filteredProducts) {
                    filteredProducts = filteredProducts.filter((p) => p.delete === filters[ProviderItemActionEnum.DELETE]);
                } else {
                    filteredProducts = products.filter((p) => p.delete === filters[ProviderItemActionEnum.DELETE]);
                }
            }
            if (filters[ProviderItemActionEnum.FORCE_UPDATE] !== null) {
                if (filteredProducts) {
                    filteredProducts = filteredProducts.filter((p) => p.force_update === filters[ProviderItemActionEnum.FORCE_UPDATE]);
                } else {
                    filteredProducts = products.filter((p) => p.force_update === filters[ProviderItemActionEnum.FORCE_UPDATE]);
                }
            }
            if (filters[ProviderItemActionEnum.VERIFIED] !== null) {
                if (filteredProducts) {
                    filteredProducts = filteredProducts.filter((p) => p.verified === filters[ProviderItemActionEnum.VERIFIED]);
                } else {
                    filteredProducts = products.filter((p) => p.verified === filters[ProviderItemActionEnum.VERIFIED]);
                }
            }
            if (filters[ProviderItemActionEnum.SYNC] !== null) {
                if (filteredProducts) {
                    filteredProducts = filteredProducts.filter((p) => p.sync === filters[ProviderItemActionEnum.SYNC]);
                } else {
                    filteredProducts = products.filter((p) => p.sync === filters[ProviderItemActionEnum.SYNC]);
                }
            }
            if (filters.new !== null) {
                if (filteredProducts) {
                    filteredProducts = filteredProducts.filter((p) => (filters.new ? !p.sku : !!p.sku));
                } else {
                    filteredProducts = products.filter((p) => (filters.new ? !p.sku : !!p.sku));
                }
            }
            if (!filteredProducts) {
                filteredProducts = products;
            }
        }

        return {
            filteredProducts,
            pagedFilteredProducts: filteredProducts?.slice(filters.page * 25, filters.page * 25 + 25),
            count: filteredProducts ? filteredProducts.length : 0,
        };
    });

    readonly updateFilters = this.updater((state, payload: { [key: string]: any }) => ({
        ...state,
        providerProductsFilters: { ...state.providerProductsFilters, ...payload },
    }));

    readonly toggleSyncing = this.updater((state, id: string) => {
        const syncing = new Set([...state.syncing.values()]);

        if (syncing.has(id)) {
            syncing.delete(id);
        } else {
            syncing.add(id);
        }

        return { ...state, syncing };
    });

    readonly updateQuery = this.updater((state, payload: any) => {
        const query = { ...state.query, ...{ ...payload, categoryInput: undefined } };
        if (!query.search || query.search.trim() === '') delete query.search;
        this.router.navigate([], { relativeTo: this.route, queryParams: query });

        return { ...state, query };
    });

    readonly updateProviderItemAfterAction = this.updater((state, providerItem: ProviderItem) => {
        const providerProducts = [...state.providerProducts];
        const idx = providerProducts.findIndex((pp) => providerItem.providerId === pp.providerId && providerItem.sku === pp.sku);

        if (idx !== -1) {
            providerProducts.splice(idx, 1, { ...providerItem, feed: providerProducts[idx]?.feed });
        }

        return { ...state, providerProducts };
    });

    readonly getProvidersEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) =>
                this.http.get<Provider[]>('/api/providers').pipe(
                    tap((providers) => this.patchState({ providers, selectedProvider: null })),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly getProviderEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            switchMap((id) =>
                this.http.get<Provider>(`api/providers/${id}`).pipe(
                    tap((provider) => {
                        const providers = this.get().providers;
                        const index = providers.findIndex((p) => p.id === provider.id);
                        if (index !== -1) {
                            providers.splice(index, 1, provider);
                        }

                        this.patchState({ providers, selectedProvider: provider });
                    }),
                ),
            ),
        ),
    );

    readonly deleteProviderEffect = this.effect((payload$: Observable<{ id: string; newProvider: string | null }>) =>
        payload$.pipe(
            switchMap((payload) =>
                this.http.delete(`api/providers/${payload.id}`, { body: { newProvider: payload.newProvider } }).pipe(
                    tap((_) => {
                        this.toastr.success('Furnizorul a fost sters cu succes.');
                        this.getProvidersEffect();
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly syncProvidersEffect = this.effect(($) =>
        $.pipe(
            tap(() => {
                this.toastr.success('Sincronizare inceputa');
            }),
            switchMap(() =>
                this.http.get(`api/providers/sync`).pipe(
                    tap((products) => {
                        this.getProvidersEffect();
                    }),
                ),
            ),
        ),
    );

    readonly syncProviderItemEffect = this.effect((providerItem$: Observable<ProviderItem>) =>
        providerItem$.pipe(
            switchMap((providerItem) =>
                this.http.post<ProviderItem>(`/api/providers/item/sync/${this.get().selectedProvider?.id}/${encodeURIComponent(providerItem.sku)}`, {}).pipe(
                    tap((providerItem) => {
                        this.toastr.success(`Produsul cu SKU ${providerItem.sku} a fost sincronizat!`);
                        this.updateProviderItemAfterAction(providerItem);
                    }),
                    catchError((err) => {
                        this.toastr.error(err);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly syncProviderEffect = this.effect((provider$: Observable<Provider>) =>
        provider$.pipe(
            mergeMap((provider) => {
                if (provider) {
                    this.toastr.success(`Sincronizarea furnizorului ${provider.name} a fost pornita!`);
                    this.toggleSyncing(provider.id);
                    return this.http.post(`/api/providers/sync/${provider.id}`, {}).pipe(
                        tap({
                            next: () => {
                                this.toastr.success(`Syncronizarea furnizorului ${provider.name} a fost finalizata.`);
                                this.toggleSyncing(provider.id);
                                this.getProvidersEffect();
                            },
                            error: (err) => {
                                this.toggleSyncing(provider.id);
                                this.toastr.error(err.error.message);
                            },
                        }),
                    );
                } else {
                    this.toastr.error('Sincronizarea nu a putut fi pornita!');
                    return EMPTY;
                }
            }),
        ),
    );

    readonly syncProviderItemsEffect = this.effect(($) =>
        $.pipe(
            switchMap(() => {
                const provider = this.get().selectedProvider;
                this.patchState({ syncProviderItems: true });

                this.toastr.success(`Sincronizarea produselor furnizorului ${provider.name} a fost pornita!`);
                return this.http.patch(`/api/providers/sync/${provider.id}`, {}).pipe(
                    tap({
                        next: () => {
                            this.toastr.success(`Syncronizarea produselor furnizorului ${provider.name} a fost finalizata.`);
                            this.getProviderFeedDetails();
                            this.patchState({ providerProducts: null, syncProviderItems: false });
                        },
                        error: (err) => {
                            this.getProviderFeedDetails();
                            this.patchState({ providerProducts: null, syncProviderItems: false });
                            this.toastr.error(err.error.message);
                        },
                    }),
                );
            }),
        ),
    );

    getProviderFeedDetails = this.effect(($) =>
        $.pipe(
            switchMap((_) =>
                this.http.get(`/api/providers/${this.get().selectedProvider?.id}/feed-details`).pipe(tap((providerProducts) => this.patchState({ providerProducts }))),
            ),
        ),
    );

    readonly getLegalInfosEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) =>
                this.http.get<LegalInfo[]>('api/providers/legal-infos/all').pipe(
                    tap((legalInfos) => this.patchState({ legalInfos })),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly saveProviderEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) => {
                const provider = this.get().selectedProvider;
                let data = this.get().providerFormGroup.value;
                data = {
                    ...data,
                    legal_info: undefined,
                    legalInfoId: data.legal_info?.id,
                };

                if (provider) {
                    return this.http.patch<Provider>(`/api/providers/${provider.id}`, data).pipe(
                        tap((provider) => {
                            this.toastr.success('Furnizorul a fost modificat cu success');
                            this.patchState({ selectedProvider: null });
                            this.router.navigate([WebcoffeeLinks.providers]);
                        }),
                        catchError((err) => {
                            this.toastr.error(err.error.message);
                            return EMPTY;
                        }),
                    );
                } else {
                    return this.http.post<Provider>('/api/providers', data).pipe(
                        tap((provider) => {
                            this.toastr.success('Furnizorul a fost adaugat cu success');
                            this.patchState({ selectedProvider: null });
                            this.router.navigate([WebcoffeeLinks.providers]);
                        }),
                        catchError((err) => {
                            this.toastr.error(err.error.message);
                            return EMPTY;
                        }),
                    );
                }
            }),
        ),
    );

    readonly toggleProviderItemAction = this.effect((providerItemAction$: Observable<{ providerItem: Partial<ProviderItem>; action: ProviderItemActionEnum }>) =>
        providerItemAction$.pipe(
            switchMap((providerItemAction) =>
                this.http.patch(`/api/providers/${providerItemAction.action}/${providerItemAction.providerItem.providerId}/${providerItemAction.providerItem.sku}`, {}).pipe(
                    tap({
                        next: (providerItem: ProviderItem) => {
                            if (providerItemAction.providerItem.sku === 'all' || providerItemAction.providerItem.sku === 'none') {
                                this.patchState({ providerProducts: null });
                                this.getProviderFeedDetails();
                                return;
                            }

                            this.updateProviderItemAfterAction(providerItem);
                        },
                        error: (err) => this.toastr.error(err.error.message),
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    override ngOnDestroy(): void {
        this.patchState({ syncing: new Set() });
    }
}
